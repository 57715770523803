export const symptoms = [
  "Abdominal guarding",
  "abdominal pain",
  "abdominal pain associated with menstruation",
  "absence of a pulse",
  "aggressiveness",
  "agitation",
  "ankle deformity",
  "ankle swelling",
  "anxiety",
  "arm swelling",
  "back deformity",
  "back pain",
  "black stools",
  "blackening of vision",
  "blackhead",
  "bleeding from vagina",
  "bleeding in the conjunctiva of the eye",
  "bloated feeling in the stomach",
  "blood in stool",
  "bloody cough",
  "blue colored skin",
  "blue spot on skin",
  "blurred vision",
  "bold area among hair on the head",
  "bone fracture",
  "breathing-related pains",
  "brittleness of nails",
  "bulging abdominal wall",
  "burning eyes",
  "burning in the throat",
  "burning nose",
  "burning sensation when urinating",
  "changes in the nails",
  "cheek swelling",
  "chest pain",
  "chest tightness",
  "chills",
  "coarsening of the skin structure",
  "cold feet",
  "cold hands",
  "cold sweats",
  "cough",
  "cough with sputum",
  "cramps",
  "cravings",
  "crusting",
  "curvature of the spine",
  "dark urine",
  "decreased urine stream",
  "delayed start to urination",
  "diarrhea",
  "difficult defecation",
  "difficulty in finding words",
  "difficulty in speaking",
  "difficulty in swallowing",
  "difficulty to concentrate",
  "discoloration of nails",
  "disorientation regarding time or place",
  "distended abdomen",
  "dizziness",
  "double vision",
  "double vision, acute-onset",
  "dribbling after urination",
  "drooping eyelid",
  "drowsiness",
  "dry eyes",
  "dry mouth",
  "dry skin",
  "earache",
  "early satiety",
  "eye blinking",
  "eye pain",
  "eye redness",
  "eyelid swelling",
  "eyelids sticking together",
  "face pain",
  "facial paralysis",
  "facial swelling",
  "fast, deepened breathing",
  "fatty defecation",
  "feeling faint",
  "feeling of foreign body in the eye",
  "feeling of pressure in the ear",
  "feeling of residual urine",
  "feeling of tension in the legs",
  "fever",
  "finger deformity",
  "flaking skin",
  "flaking skin on the head",
  "flatulence",
  "foot pain",
  "foot swelling",
  "forgetfulness",
  "formation of blisters on a skin area",
  "foul smelling defecation",
  "frequent urination",
  "genital warts",
  "hair loss",
  "hallucination",
  "halo",
  "hand pain",
  "hand swelling",
  "hard defecation",
  "hardening of the skin",
  "headache",
  "hearing loss",
  "heart murmur",
  "heartburn",
  "hiccups",
  "hip deformity",
  "hip pain",
  "hoarseness",
  "hot flushes",
  "immobilization",
  "impaired balance",
  "impaired hearing",
  "impaired light-dark adaptation",
  "impairment of male potency",
  "incomplete defecation",
  "increased appetite",
  "increased drive",
  "increased salivation",
  "increased thirst",
  "increased touch sensitivity",
  "increased urine quantity",
  "involuntary movements",
  "irregular heartbeat",
  "irregular mole",
  "itching eyes",
  "itching in the ear",
  "itching in the mouth or throat",
  "itching in the nose",
  "itching of skin",
  "itching of the anus",
  "itching on head",
  "itching or burning in the genital area",
  "joint effusion",
  "joint instability",
  "joint pain",
  "joint redness",
  "joint swelling",
  "joylessness",
  "knee deformity",
  "knee pain",
  "leg cramps",
  "leg swelling",
  "leg ulcer",
  "less than 3 defecations per week",
  "limited mobility of the ankle",
  "limited mobility of the back",
  "limited mobility of the fingers",
  "limited mobility of the hip",
  "limited mobility of the leg",
  "lip swelling",
  "lockjaw",
  "loss of eye lashes",
  "lower abdominal pain",
  "lower-back pain",
  "lump in the breast",
  "malposition of the testicles",
  "marked veins",
  "memory gap",
  "menstruation disorder",
  "missed period",
  "moist and softened skin",
  "mood swings",
  "morning stiffness",
  "mouth pain",
  "mouth ulcers",
  "muscle pain",
  "muscle stiffness",
  "muscle weakness",
  "muscular atrophy in the leg",
  "muscular atrophy of the arm",
  "muscular weakness in the arm",
  "muscular weakness in the leg",
  "nausea",
  "neck pain",
  "neck stiffness",
  "nervousness",
  "night cough",
  "night sweats",
  "non-healing skin wound",
  "nosebleed",
  "numbness in the arm",
  "numbness in the leg",
  "numbness of the hands",
  "oversensitivity to light",
  "overweight",
  "pain in the bones",
  "pain in the calves",
  "pain in the limbs",
  "pain of the anus",
  "pain on swallowing",
  "pain radiating to the arm",
  "pain radiating to the leg",
  "pain when chewing",
  "painful defecation",
  "painful urination",
  "pallor",
  "palpitations",
  "paralysis",
  "physical inactivity",
  "problems with the sense of touch in the face",
  "problems with the sense of touch in the feet",
  "protrusion of the eyes",
  "purulent discharge from the urethra",
  "purulent discharge from the vagina",
  "rebound tenderness",
  "reduced appetite",
  "ringing in the ear",
  "runny nose",
  "sadness",
  "scalp redness",
  "scar",
  "sensitivity to cold",
  "sensitivity to glare",
  "sensitivity to noise",
  "shiny red tongue",
  "shortness of breath",
  "side pain",
  "skin lesion",
  "skin nodules",
  "skin rash",
  "skin redness",
  "skin thickening",
  "skin wheal",
  "sleepiness with spontaneous falling asleep",
  "sleeplessness",
  "sneezing",
  "sore throat",
  "sputum",
  "stomach burning",
  "stress-related leg pain",
  "stuffy nose",
  "sweating",
  "swelling in the genital area",
  "swelling of the testicles",
  "swollen glands in the armpit",
  "swollen glands in the groin",
  "swollen glands in the neck",
  "tears",
  "testicular pain",
  "tic",
  "tingling",
  "tiredness",
  "toe deformity",
  "toe swelling",
  "tongue burning",
  "tongue swelling",
  "tremor at rest",
  "tremor on movement",
  "trouble understanding speech",
  "unconsciousness, short",
  "uncontrolled defecation",
  "underweight",
  "urge to urinate",
  "urination during the night",
  "vision impairment",
  "vision impairment for far objects",
  "vision impairment for near objects",
  "visual field loss",
  "vomiting",
  "vomiting blood",
  "weakness or numbness on right or left side of body",
  "weight gain",
  "weight loss",
  "wheezing",
  "wound",
  "yellow colored skin",
  "or yellowish discoloration of the white part of the eye",
];
