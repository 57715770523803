import React from "react";

import InputForm from "./InputForm";

export default function Diagnose() {
  return (
    <div>
      <div className="container-fluid" id="diagnose">
        <InputForm />
      </div>
    </div>
  );
}
